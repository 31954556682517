.video-container {
    display: flex;
}

.video-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.video {
    width: 580px;
    margin: 8px;
}

.video {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}