.about-us {
    background-color: #fff;
}

.title {
    padding: 30px 0;
    font-family: 'Montserrat Alternates';
    font-weight: 600;
    font-size: 1.5rem;
}

.description  p {
    font-family: 'Montserrat Alternates';
    font-weight: 300;
    text-align: left;
    padding-bottom: 30px;
}