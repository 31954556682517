.member {
    display: flex;
    justify-content: center;
}

.user {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-areas: "stack";
}

.user > * {
    grid-area: stack;
}

.user img {
    width: 100%;
    height: auto;
    filter: grayscale();
    transition: transform 0.3s ease;
}

.user > figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-family: 'Montserrat Alternates';
    font-weight: 700;
    display: grid;
    align-items: end;
    padding: 1.5rem 2rem;
    background-image: linear-gradient(
        to bottom,
        transparent 0%,
        #000 100%
    );
    transition: opacity 0.3s ease;
}

.user figcaption {

    width: 100%;
    opacity: 0;
    transition: 300ms;
}

.user:hover img {
    transform: scale(1.2);
    filter: none;
}

.user:hover figcaption {
    opacity: 1;
    transition: opacity 0.5s;
}

