@media screen and (max-width: 992px) {
    .footer-container {
        flex-direction: column;
    }
}

.footer {
    width: 100%;
    height: auto;
    background-color: #000;
    font-family: 'Montserrat';
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    margin: 0 32px;
}

.footer > .container-fluid > .footer-container > .footer-section .logo img {
    margin-top: 30px;
}

.social-media {
    margin: 16px;
}

.social-media .fb, .ig {
    margin: 8px;
    fill: #6D6E71;
}

.social-media .fb:hover, .ig:hover {
    cursor: pointer;
    fill: #fff;
}

.copyright {
    margin-top: 30px;
    padding-bottom: 30px;
    font-weight: 500;
    color: #6D6E71;
}

.nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    padding-left: 0;
}

.nav-links > h4 {
    color: #6D6E71;
    margin-bottom: 0.5rem;
}

.nav-link {
    font-size: 0.9rem;
    color: #6D6E71;
    line-height: 0.7rem;
    cursor: pointer;
    padding-left: 0;
}

.nav-link:hover {
    color: #fff;
}