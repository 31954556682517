.offer {
    background-color: #fff;
}

.offer .title {
    padding: 30px 0;
    font-family: 'Montserrat Alternates';
    font-weight: 600;
    font-size: 1.5rem;
}

h4 {
    font-family: 'Montserrat Alternates';
    font-weight: 300;
    text-align: left;
    margin-bottom: 30px;
}

p {
    font-family: 'Montserrat Alternates';
    font-weight: 300;
    text-align: left;
    margin-bottom: 30px;
}

p:last-child {
    margin-bottom: 0;
}

p strong {
    font-weight: 600;
}

