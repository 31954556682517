.contact {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.title {
    padding: 30px 0;
    font-family: 'Montserrat Alternates';
    font-weight: 600;
    font-size: 1.5rem;
}

.contact-section {
    padding: 0 8px;
}

.form {
    padding-top: 30px;
}

.contact-container :nth-of-type(4) {
    /* margin-left: 150px; */
}

.mb-3 {
    width: 300px;
}

.mb-3 > input::placeholder, .mb-3 > textarea::placeholder {
    opacity: 70%;
}

h4 {
    margin: 30px 0;
    font-family: 'Montserrat Alternates';
    font-weight: 600;
}

p {
    font-family: 'Montserrat Alternates';
    font-weight: 300;
    text-align: left;
    margin-bottom: 30px;
}

.gallery-tab {
    margin: 8px;
    letter-spacing: 0.1em;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 45px;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
}

.gallery-tab:hover {
    text-decoration: none;
}

.effect01 {
    color: #FFF;
    border: 1px solid #000;
    box-shadow:0px 0px 0px 1px #000 inset;
    background-color: #000;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.effect01:hover {
    border: 1px solid #000;
    background-color: #FFF;
    box-shadow:0px 0px 0px 4px #EEE inset;
}
  
.effect01 span {
    transition: all 0.2s ease-out;
    z-index: 2;
}

.effect01:hover span{
    letter-spacing: 0.13em;
    color: #000;
}
  
.effect01:after {
    background: #FFF;
    border: 0px solid #000;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .8;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
    width: 50px;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 1;
}

.effect01:hover:after {
    background: #FFF;
    border: 20px solid #000;
    opacity: 0;
    left: 120%;
    -webkit-transform: rotate(40deg);
        transform: rotate(40deg);
}   

textarea {
    resize: none;
}
