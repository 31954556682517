.thumbnail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.thumbnail > img {
    width: 580px;
    margin: 8px;
    cursor: pointer;
}

.thumbnail > img:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

.thumbnail-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
