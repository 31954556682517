.navbar-collapse {
    flex-grow: 0;
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 2;
    font-family: 'Montserrat Alternates';
}

.navbar img {
    margin: 0 16px;
}

.navbar-toggler {
    border-color: transparent !important;
}

.navbar-toggler:focus {
    box-shadow: 0 !important;
}

.nav-link {
    cursor: pointer;
}

